body{
  	margin: 0;
	font-weight: 400;
	line-height: 1;
	font-size: 87.5%;
}
html {
	margin: 0;
	font-weight: 400;
	line-height: 1;
	font-size: 14px;
}
h1,h2,h3,h4,h5,p{
	margin: 0;padding: 0;
	font-weight: 400;
}
*{
	margin: 0;padding: 0;
}
buttom{
  cursor: pointer
}
.mobile-ma{
	position: relative;
	width: 100%;
	height: 100%;
}
.component,
body,
html {
	height: 100%;
	width: 100%;
	background-color: black;
}
.component:not(.component-m) {
	min-height: 100vh;
}
#ios{
    overflow-x:scroll;
    -webkit-overflow-scrolling: touch;
}
.max-home #mainHeader{
  position: fixed;
	top: 0;
	left: 0;
	/* right: 15px; */
  z-index: 111;
  transition: opacity 0.3s
}
.max-home #header{
	width: 1230px;
	height: 104px;
	line-height: 104px;
	margin: 0 auto;
	color: white;
	padding: 0px 40px;
}

#mainHeader .responsive-header {
	display: none;
	height: 73px;
	line-height: 73px;
	margin: 0 auto;
	color: #fff;
	padding: 0px 40px;
	position: relative;
}

#mainHeader .responsive-header-nav {
	position: absolute;
	top: 0;
	width: 30px;
	height: 100%;
	left: 40px;
}

#mainHeader .responsive-header-nav-button{
	padding-top: 30px;
	cursor: pointer;
}
#mainHeader .responsive-header-nav-button>i{
	display: block;
	margin-bottom: 8px;
	width: 25px;
	height: 1px;
	background-color: #FFFFFF;
}
#mainHeader .responsive-nav-title {
	margin-left: 50px;
	width: 172px;
	display: inline-block;
}
#mainHeader .responsive-nav-title img {
	width: 172px;
	height: 16px;
	margin-top: 5px;
	cursor: pointer;
}
#mainHeader .responsive-header-right {
	float: right;
	position: relative;
}
#mainHeader .responsive-header-home-cart{
	display: inline-block;
	width: 40px;
	height: 73px;
	line-height: 73px;
	position: relative;
	margin-right: 15px;
	cursor: pointer;
}
#mainHeader .responsive-header-home-cart>img{
	width: 24px;
	height: 19px;
	display: inline-block;
}
#mainHeader .responsive-header-product-num{
	position: absolute;
	top: 20px;
	right: 6px;
	width: 14px;
	height: 14px;
	line-height: 14px;
	text-align: center;
}
#mainHeader .responsive-header-product-num>i{
	display: inline-block;
	width: 14px;
	height: 14px;
	background-color: #FFFFFF;
	border-radius: 50%;
}
#mainHeader .responsive-header-product-num>span{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	font-size: 10px;
	color: #000000;
}
#mainHeader .responsive-header-right-button{
	display: inline-block;
}
#mainHeader .responsive-header-right-button>button, #mainHeader .responsive-header-right-button>a{
	display: inline-block;
	margin-top: 20px;
	padding: 10px 15px;
	line-height: 14px;
	border: none;
	color: #000;
	font-size: 12px;
	text-align: center;
	background-color: #F8E71C;
}

.max-home .responsive-header-menu {
	position: absolute;
	top: 0;
	z-index: 11;
	width: 100%;
	height: 100%;
	color: #FFFFFF;
	background-color: #000000;
}

@media screen and (max-width: 490px) {
	#mainHeader .responsive-header {
		padding: 0px 15px;
	}
	#mainHeader .responsive-header-nav {
		left: 15px;
		width: 20px;
	}
	#mainHeader .responsive-header-nav-button>i {
		width: 20px;
		margin-bottom: 6px;
	}
	#mainHeader .responsive-nav-title {
		margin-left: 35px;
	}
	#mainHeader .responsive-nav-title img {
		margin-top: 0px;
	}
	#mainHeader .responsive-header-home-cart {
		margin-right: 10px;
	}
	#mainHeader .responsive-header-right-button>button, #mainHeader .responsive-header-right-button>a {
		padding: 8px 10px;
	}
}

@media screen and (max-width: 1160px) {
	#mainHeader .responsive-header {
		display: block;
	}
	#mainHeader #header {
		display: none;
	}
}

.max-home .page-section {
	width: 100%;
	height: 100%
}

.max-home .page-section-m {
	height: 100%;
}

.max-home .pagination-additional {
	padding:0;
	margin: 0 auto;
	/* position: fixed; */
	/* top: 0; */
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	/* -webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center; */
	/* width: 937px; */
	list-style: none;
	float: left;
}
.max-home .pagination-additional>li{
	line-height: 104px;
	position: relative;
	padding: 0px 3px 0px 3px;
}
.max-home .pagination-additional>li>a{
	display: block;
	height: 100%;
	position: relative;
	margin-right: 27px;
	color:white;
	font-size:16px;
	text-decoration: none;
	 padding: 0;
     background-color: transparent; 
    border: none; 	
}
@media screen and (max-width: 1350px){
	.max-home .pagination-additional>li>a{
		font-size: 13px;
	}
}

.max-home .pagination-additional>li>a>img{
	/*margin-top: 27px;*/
}
.max-home .pagination-additional>li>a>span{
	position: absolute;
	left: 0;
	bottom: 25px;
	display: block;
	width: 0px;
    height: 3px;
    border-radius: 1.5px;
    border: 2px;
	transition: width 0.5s;	
}
.max-home .pagination-additional>li>a{
	background-color: transparent !important;
}

.max-home .pagination-additional>li>a:hover{
	text-shadow: 0 0 20px #ffffff, 0 0 10px #ffffff, 0 0 50px #ffffff;
}
.max-home .pagination-additional>li>a>.current{
	background-color: #fff;
	box-shadow: 0 0 5px #d2f9be, 0 0 8px #d2f9be, 0 0 50px #d2f9be;
	width: 100%;
}
.max-home .header-right{
	float: right;
	/* width: 293px; */
}
.max-home .header-right>ul{list-style: none;margin: 0;}
.max-home .header-right>ul>li{
	position: relative;
	float: right;
	margin-left: 18px;
	height: 104px;
	line-height: 104px;
}
.max-home .header-right>ul>li.header-language {
	min-width:90px;
	text-align: center;
}
.max-home .header-right .button-primary{
	display: inline-block;
	margin: 0;
	font-size: 14px;
	color:black;
	/*width: 100px;*/
	padding-left: 10px;
	padding-right: 10px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	background-color: #f7e53b;
	font-weight: bold;
	
	border-style: solid;
	border-width: 2px;
	border-color:#f7e53b;
	background-color: #f7e53bff;
	font-size: 14px;
	font-weight: bold;
	transition: background-color 0.2s, text-shadow 00.7s;
}
.max-home .header-right .button-primary:hover{
	color: #f7e53b;
	background-color:#00000066;
	text-shadow: 0 0 20px #ffffff, 0 0 10px #f7e53b, 0 0 50px #f7e53b;
}
.max-home #nav .nav-logo-icon {
	width: 215px;
	height: 20px;
	margin-top: -5px;
}
.max-home .header-right .cart .cart-icon {
	width: 20px;
	height: 17px;
	margin-top: -5px;
}
.max-home .header-right .account-icon {
	width: 15px;
	height: 16px;
	margin-top: -5px;
}
.max-home .cart-hover{
	display: none;
	position: absolute;
	top: 76px;
	left: -70px;
	width: 160px;
	height: 45px;
	line-height: 45px;
	text-align: center;
	color: #000;
	background-color: #fff;
  display: block;
}
.max-home .cart-hover>i{
	position: absolute;
	top: -12px;
	left: 76.5px;
	display: inline-block;
	border: 6px solid;
	border-color:transparent transparent #fff transparent;
}
.max-home .header-right .cart:hover .cart-hover{
	/* display: block; */
}
.max-home .component {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.max-home .component.component-m {
	display: block;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
}

.max-home .component.first-component {
	background-color: black;
	width: 100%;
	position: relative;
	overflow: visible;
}
/*首页*/
.max-home .home-video{
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    width: 100%;
    height: 100%;
	overflow: hidden;
	background:#000;
}
.max-home .home-video>video{
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
}
.max-home .mobile-ma .video-hold {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
}
.max-home .first-component .content{
	margin: 104px auto 0;
    width: 1156px;
    line-height: 100%;
    position: relative;
    display: inline-block;
}
.concept-store-popup {
	position: absolute;
	width: 900px;
	height: 520px;
	background-color: #FFF;
	top: 50%;
	margin-top: -240px;
	left: 50%;
	margin-left: -450px;
	z-index: 20;
	text-align: center;
	padding: 10px;
}
.concept-store-inner {
	height: 100%;
	width: 100%;
	border: 2px solid #000;
	padding-left: 30px;
	padding-right: 30px;
}
.concept-store-popup .close-btn {
	position: absolute;
	right: 25px;
	top: 15px;
	font-size: 30px;
	cursor: pointer;
}
.concept-store-popup h3 {
	text-align: center;
	margin-top: 30px;
	margin-bottom: 20px;
	font-size: 26px;
	/*font-weight: bold;*/
	/*text-transform: uppercase;*/
}
.concept-store-popup .storepic1 {
	height: 220px;
	margin-bottom: 20px;
	margin-left: 4px;
	margin-right: 4px;
}
.concept-store-popup p {
	font-size: 14px;
	line-height: 20px;
}
.concept-store-popup p .bold {
	font-weight: bold;
}
.concept-store-popup p .italic {
	font-style: italic;
}
.concept-store-popup a {
	text-decoration: underline;
	color: #28a6d4;
}
.concept-store-popup .disney-logo {
	position: absolute;
	bottom: 25px;
	right: 25px;
}
.concept-store-popup .disney-logo img {
	width: 106px;
	height: 32px;
}

.concept-store-popup > .popup-close {
	position: absolute;
	top: -10px;
	right: -10px;
	width: 30px;
	height: 30px;
	background-image: url("../image/home/popup-close.png");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	cursor: pointer;
	z-index: 2;
}

.lead-popup-item {
	height: 100%;
	width: 100%;
}

.lead-popup-item img {
	height: 100%;
	width: 100%;
}

/* start carousel */
.concept-store-popup > div {
	height: 100%;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
	-webkit-transition: all 0.25s ease-in;
	-moz-transition: all 0.25s ease-in;
	-ms-transition: all 0.25s ease-in;
	-o-transition: all 0.25s ease-in;
	transition: all 0.25s ease-in;
	opacity: 0.4;
	filter: alpha(opacity=40);
	position: absolute;
	z-index: 2;
	top: 20px;
	background: none;
	border: 0;
	font-size: 32px;
	cursor: pointer; }
	.carousel .control-arrow:hover {
	  opacity: 1;
	  filter: alpha(opacity=100); }
	.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
	  margin: 0 5px;
	  display: inline-block;
	  border-top: 8px solid transparent;
	  border-bottom: 8px solid transparent;
	  content: ''; }
	.carousel .control-disabled.control-arrow {
	  opacity: 0;
	  filter: alpha(opacity=0);
	  cursor: inherit;
	  display: none; }
	.carousel .control-prev.control-arrow {
	  left: 0; }
	  .carousel .control-prev.control-arrow:before {
		border-right: 8px solid #fff; }
	.carousel .control-next.control-arrow {
	  right: 0; }
	  .carousel .control-next.control-arrow:before {
		border-left: 8px solid #fff; }
  
  .carousel {
	position: relative;
	width: 100%; }
	.carousel * {
	  -webkit-box-sizing: border-box;
	  -moz-box-sizing: border-box;
	  box-sizing: border-box; }
	/* .carousel img {
	  width: 100%;
	  display: inline-block;
	  pointer-events: none; } */
	.carousel .carousel {
	  position: relative; }
	.carousel .control-arrow {
	  outline: 0;
	  border: 0;
	  background: none;
	  top: 50%;
	  margin-top: -13px;
	  font-size: 18px; }
	.carousel .thumbs-wrapper {
	  margin: 20px;
	  overflow: hidden; }
	.carousel .thumbs {
	  -webkit-transition: all 0.15s ease-in;
	  -moz-transition: all 0.15s ease-in;
	  -ms-transition: all 0.15s ease-in;
	  -o-transition: all 0.15s ease-in;
	  transition: all 0.15s ease-in;
	  -webkit-transform: translate3d(0, 0, 0);
	  -moz-transform: translate3d(0, 0, 0);
	  -ms-transform: translate3d(0, 0, 0);
	  -o-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	  position: relative;
	  list-style: none;
	  white-space: nowrap; }
	.carousel .thumb {
	  -webkit-transition: border 0.15s ease-in;
	  -moz-transition: border 0.15s ease-in;
	  -ms-transition: border 0.15s ease-in;
	  -o-transition: border 0.15s ease-in;
	  transition: border 0.15s ease-in;
	  display: inline-block;
	  width: 80px;
	  margin-right: 6px;
	  white-space: nowrap;
	  overflow: hidden;
	  border: 3px solid #fff;
	  padding: 2px; }
	  .carousel .thumb:focus {
		border: 3px solid #ccc;
		outline: none; }
	  .carousel .thumb.selected, .carousel .thumb:hover {
		border: 3px solid #333; }
	  .carousel .thumb img {
		vertical-align: top; }
	.carousel.carousel-slider {
	  position: relative;
	  margin: 0;
	  overflow: hidden;
		height: 100%; }
	  .carousel.carousel-slider .control-arrow {
		top: 0;
		color: #fff;
		font-size: 26px;
		bottom: 0;
		margin-top: 0;
		padding: 5px; }
		.carousel.carousel-slider .control-arrow:hover {
		  background: rgba(0, 0, 0, 0.2); }
	.carousel .slider-wrapper {
	  overflow: hidden;
	  margin: auto;
	  width: 100%;
	  height: 100%;
	  -webkit-transition: height 0.15s ease-in;
	  -moz-transition: height 0.15s ease-in;
	  -ms-transition: height 0.15s ease-in;
	  -o-transition: height 0.15s ease-in;
	  transition: height 0.15s ease-in; }
	  .carousel .slider-wrapper.axis-horizontal .slider {
		-ms-box-orient: horizontal;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -moz-flex;
		display: -webkit-flex;
		display: flex;
		height: 100%; }
		.carousel .slider-wrapper.axis-horizontal .slider .slide {
		  flex-direction: column;
		  flex-flow: column; }
	  .carousel .slider-wrapper.axis-vertical {
		-ms-box-orient: horizontal;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -moz-flex;
		display: -webkit-flex;
		display: flex; }
		.carousel .slider-wrapper.axis-vertical .slider {
		  -webkit-flex-direction: column;
		  flex-direction: column; }
	.carousel .slider {
	  margin: 0;
	  padding: 0;
	  position: relative;
	  list-style: none;
	  width: 100%; }
	  .carousel .slider.animated {
		-webkit-transition: all 0.35s ease-in-out;
		-moz-transition: all 0.35s ease-in-out;
		-ms-transition: all 0.35s ease-in-out;
		-o-transition: all 0.35s ease-in-out;
		transition: all 0.35s ease-in-out; }
	.carousel .slide {
	  min-width: 100%;
	  margin: 0;
	  position: relative;
	  text-align: center;
	  background: #FFF; }
	  /* .carousel .slide img {
		width: 100%;
		vertical-align: top;
		border: 0; } */
	  .carousel .slide iframe {
		display: inline-block;
		width: calc(100% - 80px);
		margin: 0 40px 40px;
		border: 0; }
	  .carousel .slide .legend {
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		position: absolute;
		bottom: 40px;
		left: 50%;
		margin-left: -45%;
		width: 90%;
		border-radius: 10px;
		background: #000;
		color: #fff;
		padding: 10px;
		font-size: 12px;
		text-align: center;
		opacity: 0.25;
		-webkit-transition: opacity 0.35s ease-in-out;
		-moz-transition: opacity 0.35s ease-in-out;
		-ms-transition: opacity 0.35s ease-in-out;
		-o-transition: opacity 0.35s ease-in-out;
		transition: opacity 0.35s ease-in-out; }
	.carousel .control-dots {
	  position: absolute;
	  bottom: 0;
	  margin: 10px 0;
	  text-align: center;
	  width: 100%; }
	  @media (min-width: 960px) {
		.carousel .control-dots {
		  bottom: 0; } }
	  .carousel .control-dots .dot {
		-webkit-transition: opacity 0.25s ease-in;
		-moz-transition: opacity 0.25s ease-in;
		-ms-transition: opacity 0.25s ease-in;
		-o-transition: opacity 0.25s ease-in;
		transition: opacity 0.25s ease-in;
		opacity: 0.3;
		filter: alpha(opacity=30);
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
		background: #000;
		border-radius: 50%;
		width: 8px;
		height: 8px;
		cursor: pointer;
		display: inline-block;
		margin: 0 8px; }
		.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
		  opacity: 1;
		  filter: alpha(opacity=100); }
	.carousel .carousel-status {
	  position: absolute;
	  top: 0;
	  right: 0;
	  padding: 5px;
	  font-size: 10px;
	  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
	  color: #fff; }
	.carousel:hover .slide .legend {
	  opacity: 1; }
  
/* end carousel */

.max-home .content-inner{
	position: absolute;
	top: 50%;
	left: 10px;
	margin-top: -195px;
	padding: 0px 40px;
}
.max-home .page1 .home-falocn .btmf-logo-icon {
	width: 226px;
	height: 103px;
}
.max-home .page1 .home-falocn .deagostini-logo-icon {
	width: 118px;
	height: 15px;
	margin-top: 34px;
}
.max-home .page1 .home-disney .disney-lucas-logo-icon {
	width: 153px;
	height: 30px;
}
.max-home .home-falocn>img{
	display: block;
}
.max-home .home-falocn>img:first-child{
	margin-bottom: 12px;
}
.max-home .home-button #now{
	border-width: 2px;
	border-color:#f7e53b;
	background-color: #f7e53bff;
	font-size: 14px;
	font-weight: bold;
	transition: background-color 0.2s, text-shadow 00.7s;
}
.max-home .home-button #now:hover{
	color: #f7e53b;
	background-color:#00000066;
	text-shadow: 0 0 20px #ffffff, 0 0 10px #f7e53b, 0 0 50px #f7e53b;
}
.max-home .home-button #more{
	border-width: 2px;
	border-color:#fff;
	background-color: #ffffffff;
	font-size: 14px;
	font-weight: bold;
	transition: background-color 0.2s;
}
.max-home .home-button #more:hover{
	color: #fff;
	background-color:#00000066;
}
.max-home .home-button>button{
	height: 45px;
	width: 160px;	
}
.max-home .home-button button:first-child{
	margin-right: 13px;
}
.max-home .home-button button{
	border: solid 1px;
  cursor: pointer;
}
.max-home .home-disney{
	margin-top:21px;
}
.max-home .home-disney img {
	display: inline-block;
}
.max-home .home-disney .home-lucasfilm {
	display: inline-block;
	color: #FFF;
	margin-left: 20px;
}
.max-home .scroll-down{
	position: absolute;
	bottom: 21px;
	left: 50%;
	margin-left: -80px;
	text-align: center;
	color: #f8e71c;
}

.max-home .scroll-down>span{
	display: inline-block;
	width: 24px;
	height: 36px;
	border: solid 1px #f8e71c;
	border-radius: 13px;
}
.max-home .scroll-down>span>i{
	display: inline-block;
	width:2px;
	height: 7px;
	border-radius: 2px;
	background-color: #f8e71c;
}

@media screen and (min-width: 2000px){
	.max-home #nav .nav-logo-icon {
		width: 269px;
		height: 25px;
		margin-top: -5px;
	}

	.max-home .content-inner{
		margin-top: -245px;
	}

	.max-home .page1 .home-falocn .btmf-logo-icon {
		width: 339px;
		height: 155px;
	}
	.max-home .page1 .home-falocn .deagostini-logo-icon {
		width: 177px;
		height: 23px;
		margin-top: 51px;
	}

	.max-home .home-des{
		margin:63px 0;	
		width: 60%;
		line-height: 62px;
		font-size: 54px;
		color:#fff;
	}

	.max-home .home-button>button{
		height: auto;
		width: auto;
		padding: 20px 46px;
	}

	.max-home .home-button button:first-child{
		margin-right: 20px;
	}

	.max-home .home-button #now{
		border-color:#f7e53b;
		background-color: #f7e53b;
		font-size: 21px;
	}

	.max-home .home-button #more{
		border-color:#fff;
		background-color: #fff;
		font-size: 21px;
	}

	.max-home .home-disney{
		margin-top:32px;
	}

	.max-home .page1 .home-disney .disney-lucas-logo-icon {
		width: 230px;
		height: 45px;
		vertical-align: middle;
	}

	.max-home .home-disney .home-lucasfilm {
		margin-left: 30px;
		font-size: 21px;
		vertical-align: middle;
	}

	.max-home .scroll-down>p{
		margin: 12px 0;
		font-size: 21px;
	}
	.pcModelTwo-size>span:first-child{
		font-size: 18px;
	}

	.pcModelTwo-size>span:last-child{
		font-size: 18px;
	}

	.pcModelTwo-weight {
		display: none;
		position: absolute;
	}

	.pcModelTwo-weight img {
		width: 75px;
		height: 53px;
	}
}

@media screen and (max-width: 2000px){
	.max-home .home-des{
		margin:42px 0;	
		width: 60%;
		line-height: 43px;
		font-size: 36px;
		color:#fff;
	}

	.max-home .home-button #now{
		border-color:#f7e53b;
		background-color: #f7e53b;
		font-size: 14px;
	}

	.max-home .home-button #more{
		border-color:#fff;
		background-color: #fff;
		font-size: 14px;
	}

	.max-home .scroll-down>p{
		margin: 12px 0;
		font-size: 14px;
	}

	.pcModelTwo-size>span:first-child{
		font-size: 12px;
	}

	.pcModelTwo-size>span:last-child{
		font-size: 12px;
	}

	.pcModelTwo-weight {
		display: none;
		position: absolute;
	}

	.pcModelTwo-weight img {
		width: 2.5rem;
		height: 1.75rem;
	}
}

#pcModelTwo-sizeL{
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 3s ease-in-out;
	-moz-transition: opacity 3s ease-in-out;
	-ms-transition: opacity 3s ease-in-out;
	-o-transition: opacity 3s ease-in-out;
	transition: opacity 3s ease-in-out;

}
#pcModelTwo-sizeW{
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 3s ease-in-out;
	-moz-transition: opacity 3s ease-in-out;
	-ms-transition: opacity 3s ease-in-out;
	-o-transition: opacity 3s ease-in-out;
	transition: opacity 3s ease-in-out;

}
#pcModelTwo-sizeH{
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 3s ease-in-out;
	-moz-transition: opacity 3s ease-in-out;
	-ms-transition: opacity 3s ease-in-out;
	-o-transition: opacity 3s ease-in-out;
	transition: opacity 3s ease-in-out;

}

#pcModelTwo-weight{
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 3s ease-in-out;
	-moz-transition: opacity 3s ease-in-out;
	-ms-transition: opacity 3s ease-in-out;
	-o-transition: opacity 3s ease-in-out;
	transition: opacity 3s ease-in-out;

}

/*VIDEO*/
.max-home .video-button{
	position: absolute;
	width: 100%;
	height: 100%;
}
.max-home .video-button>img:last-child{
	display: none;
}

.max-home .video-button>img {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -30px;
	margin-left: -30px;
	width: 76px;
	height: 76px;
}

.max-home .pause-button {
	position: absolute;
	width: 100%;
	height: 85%;
	top: 0;
	left: 0;
}

.max-home .progressTime{
	position: absolute;
	left: 0;
	bottom: 55px;
	width: 100%;
	z-index: 11;
	display: none;
	height: 120px;
  }
  .max-home .progressTime .progress{
	margin: 0 auto;
	width: 80%;
	height: 1px;
	/* background-color: #999; */
	border-radius: 0.5px;
  }
  .max-home .progressTime .progress>div{
	width: 0;
	height: 1px;
	background-color:yellow;
	transition: all 0.9s;
  }
  .max-home .progressTime .console{
	padding: 1.071rem 0;
	text-align: center;
  }
  .max-home .progressTime .console>img{
	width: 1.785rem;
	height: 1.785rem;
  }
  .max-home .progressTime .console .videoPlay{
	margin: 0 1.428rem;
	display: inline-block;
	width: 3.571rem;
	height: 3.571rem;
	line-height: 3.571rem;
  }
  .max-home .progressTime .console .videoPlay>img:first-child{
	width: 100%;
	height: 100%;
  }

.console-desktop {
	display: none;
}
.max-home .progressTime:hover > .console-desktop {
	display: block;
}

/*MODEL*/
.max-home .model1,.model2{
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -50%;
	width: 100%;
	padding-top: 144px;
}
.max-home .model1{
	min-height: 674px;
    height: 100%;	
}
.max-home .model2{
	/*height: 847px;*/
}

.max-home .strengthen {
	font-weight: bold;
	font-style: italic;
}

.max-home .model1-info{
	z-index: 1;
	position: absolute;
	top: 50%;
	left: 110px;
	/* margin-left: -565px; */
	margin-top: -110px;
	width: 27%;
}

@media screen and (min-width: 2000px){
	.max-home .model1-info{
		width: 32%;
	}
	.max-home .model1-info>h3{
		line-height: 64px;
		font-size: 54px;
		color: #fff;
	}
	.max-home .model1-info>p{
		margin: 0;
		font-size: 27px;
		color: #9B9B9B;
		margin-top: 35px;
		line-height: 36px;
	}
	.max-home .model1-info>p.smallnote{
		margin: 0;
		font-size: 18px;
		color: #DDD;
		margin-top: 35px;
		line-height: 20px;
	}
}
@media screen and (max-width: 2000px){
	.max-home .model1-info>h3{
		line-height: 43px;
		font-size: 36px;
		color: #fff;
	}
	.max-home .model1-info>p{
		margin: 0;
		font-size: 18px;
		color: #9B9B9B;
		margin-top: 5%;
		line-height: 24px;
	}
	.max-home .model1-info>p.smallnote{
		margin: 0;
		font-size: 12px;
		color: #DDD;
		margin-top: 5%;
		line-height: 12px;
	}
}

.max-home .model1-video1{
	position: absolute;
	top: 50%;
	right: 0;
	/* width: 946px;
	height: 530px; */
}

.max-home .model2-info{
	position: absolute;
	top: 15px;
	/*left: 54px;*/
	z-index: 1;
}


@media screen and (min-width: 2000px){
	.max-home .model2-icon>span{
		position: absolute;
		top: 30px;
		left: 12px;
		color: #fff;
		font-size: 30px;
		font-weight: 300;
	}

	.max-home .model2-des{
		position: absolute;
		top: 3px;
		display: inline-block;
		width: 450px;
		font-size: 27px;
		line-height: 33px;
	}

	.max-home .model2-des>h4{
		color: #9B9B9B;
		margin: 0;
		font-size: 27px;
		margin-bottom: 10px;
	}

	.max-home .model2-icon>i{
		display: inline-block;
		width: 80px;
		height: 80px;
		position: absolute;
		top: 14px;
		left:-14px;
		border: 1px solid #fff;
	}

	.max-home .model2-icon{
		margin-right: 33px;
		display: inline-block;
		width: 80px;
		height: 80px;
		border: 1px solid #fff;
	}

	#pcModelTwo-sizeL>span:first-child, #pcModelTwo-sizeW>span:first-child, #pcModelTwo-sizeH>span:first-child {
		font-size: 18px;
	}
	#pcModelTwo-sizeL>span:last-child, #pcModelTwo-sizeW>span:last-child, #pcModelTwo-sizeH>span:last-child {
		margin-left: 5px;
		font-size: 21px;
	}
}

@media screen and (max-width: 2000px){
	.max-home .model2-icon>span{
		position: absolute;
		top: 20px;
		left: 8px;
		color: #fff;
		font-size: 20px;
		font-weight: 300;
	}

	.max-home .model2-des{
		position: absolute;
		top: 3px;
		display: inline-block;
		width: 377px;
		font-size: 18px;
		line-height: 22px;
	}

	.max-home .model2-des>h4{
		color: #9B9B9B;
		margin: 0;
		font-size: 18px;
	}

	.max-home .model2-icon>i{
		display: inline-block;
		width: 53px;
		height: 53px;
		position: absolute;
		top: 9px;
		left:-9px;
		border: 1px solid #fff;
	}

	.max-home .model2-icon{
		margin-right: 20px;
		display: inline-block;
		width: 53px;
		height: 53px;
		border: 1px solid #fff;
	}
}



.max-home .model2-des>p{
	color: #fff;	
	margin: 0;
}
.max-home .model2-des>p>span{
	color: #9B9B9B;
}
.max-home .model2-pic{
	position: relative;
	margin: 0 auto;
	width: 1359px;
	height: 656px;
	padding-left: 50px;
}
.max-home .model2-pic>img{
	width: 100%;
	height: 100%;
}

.max-home .null{
	width: 100%;
	height: 104px;
}
.max-home .model3,.max-home .model6,.max-home .box,.max-home .service,.max-home .shopping{
	position: absolute;
  top: 0;
  height: 100%;
  left: 50%;
  margin-left: -50%;
  width: 100%;
}
.max-home .model3{
	/* height: 1673px; */
	overflow: hidden;
}

@media screen and (min-width: 2000px){
	.max-home .model3-details{
		height: 200px;
		font-size: 54px;
		color: #FFF;
		text-align: center;
    padding-top: 46px;
	}
}

@media screen and (max-width: 2000px){
	.max-home .model3-details{
		height: 200px;
		font-size: 36px;
		color: #FFF;
		text-align: center;
    padding-top: 58px;
	}
}

.max-home .model3-video1{
	position: relative;
	height: 530px;
}
.max-home .model3-video1-1{
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
}
.max-home .model3-video2{
	position: relative;
	left: 0;
	top: 0px;
	height: 530px;
	width: 100%;	
  /* margin-top: 150px */
}
.max-home .model3-video2 .model3-video2-1{
  position: absolute;
  top: 0;
  left: 0;
  width: 932px;
  height: 100%;
}
.max-home .model5{
	/* height: 1975px; */
}
.max-home .model5-video1-2{
	position: absolute;
	bottom: 67px;
	left: 0;
	/* margin-bottom: -166px; */
}
.max-home .model5-video2{
	position: relative;
  top: 0;
  left: 0;
    /* bottom: 371px; */
    height: 530px;
    width: 100%;
    /* margin-top: 170px */
}
.max-home .model5-video2-2{
	position: absolute;
	bottom: 67px;
	right: 0;
}
.max-home .model6{
	/* height: 750px; */
}
.max-home .model6-details .smallasterisk {
	font-size: 18px;
	vertical-align: top;
}
.max-home .model6-details h4 {
	color: #fff;
	margin-top: 10px;
}
@media screen and (min-width: 2000px){

	.max-home .model6-details{
		position: relative;
		z-index: 1;
		margin-top: 5%;
		text-align: center;
	}

	.max-home .model6-details>h2{
		line-height: 48px;
		font-size: 36px;
		color:#F8E71C;
	}
	.max-home .model6-details>h1{
		margin: 17px 0;
		line-height: 57px;
		font-size: 54px;
		color:#fff;
	}
	.max-home .model6-details>p,.box-details>p{
		margin-bottom: 23px;
		line-height: 29px;
		font-size: 20px;	
		color:#F8E71C;
		text-decoration: line-through;
	}
	.max-home .model6-details>button,.box-details>button{
		padding: 21px 26px;
		text-align: center;
		font-size: 21px;
		color: black;
		background-color: #F8E71C;
		cursor: pointer;
		border-width: 2px;
		border-color:#f7e53b;
		background-color: #f7e53bff;
		font-weight: bold;
		transition: background-color 0.2s, text-shadow 00.7s;
	}
}

@media screen and (max-width: 2000px){

	.max-home .model6-details{
		position: relative;
		z-index: 1;
		margin-top: 3%;
		text-align: center;
	}


	.max-home .model6-details>h2{
		height: 32px;
		line-height: 32px;
		font-size: 24px;
		color:#F8E71C;
	}
	.max-home .model6-details>h1{
		margin: 5px 0 9px 0;
		height: 43px;
		line-height: 43px;
		font-size: 36px;
		color:#fff;
	}
	.max-home .model6-details>p,.box-details>p{
		margin-bottom: 15px;
		height: 19px;
		line-height: 19px;
		font-size: 16px;	
		color:#F8E71C;
		text-decoration: line-through;
	}
	.max-home .model6-details>button,.box-details>button{
		padding: 14px 17px;
		text-align: center;
		font-size: 14px;
		color: black;
		background-color: #F8E71C;
		cursor: pointer;
		
		border-width: 2px;
		border-color:#f7e53b;
		background-color: #f7e53bff;
		font-size: 14px;
		font-weight: bold;
		transition: background-color 0.2s, text-shadow 00.7s;
	}
}


.max-home .model6-details>button:hover,.box-details>button:hover{
	color: #f7e53b;
	background-color:#00000066;
	text-shadow: 0 0 20px #ffffff, 0 0 10px #f7e53b, 0 0 50px #f7e53b;
}

@media screen and (min-width: 2000px){
	.max-home .box-content{
		position: relative;
		margin: 0 auto;
		width: 1230px;
		/* height: 100%; */
		max-width: 1850px;
	}
}

@media screen and (max-width: 2000px){
	.max-home .box-content{
		position: relative;
		margin: 0 auto;
		width: 1230px;
		/* height: 100%; */
		max-width: 1250px;
	}
}

.max-home .box-details{
	position: relative;
	z-index: 1;
  padding-left: 40px;
}
.max-home .box-details>.title{
	height: 293px;
	margin: 0;
}

@media screen and (min-width: 2000px){
	.max-home .box-details>.title>h1{
		padding: 286px 0 0;
		color: #fff;
		margin: 0;
		font-weight: 200;
		font-size: 54px;
		line-height: 63px;
	}
	.max-home .box-details>h4{
		margin: 84px 0 35px;
		line-height: 36px;
		font-size: 27px;
		color: #9B9B9B;	
	}
}

@media screen and (max-width: 2000px){
	.max-home .box-details>.title>h1{
		padding: 256px 0 0;
		color: #fff;
		margin: 0;
		font-weight: 200;
		font-size: 36px;
	}
	.max-home .box-details>h4{
		margin: 18px 0 23px;
		line-height: 24px;
		font-size: 18px;
		color: #9B9B9B;	
	}
}

.max-home .box-pic{
	position: absolute;
	top: 173px;
	right: 0;
	width: 796px;
	height: 545px;
}
.max-home .box-pic>img{
	width: 100%;
	height: 100%;
}
.max-home .box-countdown{
	height: 445px;
	text-align: center;
	z-index: 1000;
	position: relative;
	/*visibility: hidden;*/
	display: none;
}

@media screen and (min-width: 2000px){
	.max-home .box-countdown>div{
		padding-top: 40px;
		padding-bottom: 25px;
		/* height: 18px; */
		font-size: 27px;
		color: #f1e03a;
	}

	.max-home .box-countdown>ul>li{
		display: inline-block;
		position: relative;
		float: left;
		margin-left: 8px;
		width: 50px;
		height: 75px;
		line-height: 75px;
		font-size: 48px;
		font-weight: 300;
		color: #fff;
		list-style: none;
		text-align: center;
		background-color: rgba(255,255,255,.1);
	}
}

@media screen and (max-width: 2000px){
	.max-home .box-countdown>div{
		padding-top: 40px;
		padding-bottom: 25px;
		/* height: 18px; */
		font-size: 18px;
		color: #f1e03a;
	}

	.max-home .box-countdown>ul>li{
		display: inline-block;
		position: relative;
		float: left;
		margin-left: 8px;
		width: 50px;
		height: 75px;
		line-height: 75px;
		font-size: 32px;
		font-weight: 300;
		color: #fff;
		list-style: none;
		text-align: center;
		background-color: rgba(255,255,255,.1);
	}
}


.max-home .box-countdown>ul{
	display: inline-block;
	margin:0 auto;
	font-size: 0;
}

.max-home .box-countdown>ul>li:nth-child(3n+4){
	width: 3px;	
	background-color: rgba(255,255,255,0);
}

.max-home .build>div{
	margin-bottom: 30px;
}

.max-home .build>div>img{
	width: 121px;
	height: 45px;
	vertical-align: top;
}

@media screen and (min-width: 2000px){
	.max-home .build>h4{
		padding: 45px 0;
		font-size: 21px;
		font-weight: 100;
		color: #eee;
	}

	.max-home .box-countdown>ul>li>span{
		position: absolute;
		top: 75px;
		right: 0;
		font-size: 22px;
		line-height: 45px;
		color: rgba(255,255,255,.8);
	}

	.max-home .build>div>p{
		display: inline-block;
		margin-right: 30px;
		line-height: 34px;
		font-size: 23px;
		color: #f48323;
	}

	.max-home .build>div>img{
		width: 182px;
		height: 68px;
	}

	.max-home .build>span{
		color: #9b9b9b;
		font-size: 20px;
	}
}

@media screen and (max-width: 2000px){
	.max-home .build>h4{
		padding: 30px 0;
		font-size: 14px;
		font-weight: 100;
		color: #eee;
	}

	.max-home .box-countdown>ul>li>span{
		position: absolute;
		top: 75px;
		right: 0;
		font-size: 15px;
		line-height: 45px;
		color: rgba(255,255,255,.8);
	}

	.max-home .build>div>p{
		display: inline-block;
		margin-right: 20px;
		line-height: 22.5px;
		font-size: 15px;
		color: #f48323;
	}

	.max-home .build>span{
		color: #9b9b9b;
		font-size: 13px;
	}
}

.max-home .guide{
	min-height: 503px;	
}
.max-home .service{
	/* min-height: 646px;	 */
}
.max-home .guide{
  width: 100%;
}
.max-home .guide-content{
	position: absolute;
    margin: 0 auto;
    width: 1230px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
}
.max-home .guide-inner{
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	overflow: hidden;
}
.max-home .guide-inner>.sider{
	float: left;
	width: 50%;
	height: 100%;
}
.max-home .guide-inner>.sider .sider-inner{
	position: relative;
    width: 90%;
    height: 100%;
    margin: 0 auto;
}
.max-home .sider-inner{
	position: relative;
	width: 100%;
	height: 100%;
	/* margin-left: 10%; */
}
.max-home .guide-info{
	padding: 60px 35px;
	float: right;
	width: 46%;
	height: 100%;
    overflow: hidden;
}
.max-home .guide-info-inner{	
	width: 100%;
	/* height: 276px; */
    float: right;
}

@media screen and (min-width: 2000px){
	.max-home .guide-info {
		padding: 120px 100px 0px 160px;
		float: left;
		width: 45%;
	}
	.max-home .guide-info-inner>h5{
		padding-bottom: 30px;
		line-height: 64px;
		font-size: 54px;
		font-weight: 200;
		color: #fff;
	}
	.max-home .guide-info-inner>p{
		margin-top: 45px;
		font-size: 27px;
		color: #9b9b9b;
		line-height: 32px;
	}
	.max-home .guide-info-inner>p.guide-footnote{
		font-size: 24px;
		color: #fff;
		line-height: 30px;
		font-weight: bold;
	}
}

@media screen and (max-width: 2000px){
	.max-home .guide-info-inner>h5{
		padding-bottom: 20px;
		line-height: 43px;
		font-size: 36px;
		font-weight: 200;
		color: #fff;
	}
	.max-home .guide-info-inner>p{
		margin-top: 30px;
		font-size: 18px;
		color: #9b9b9b;
		line-height: 21px;
	}
	.max-home .guide-info-inner>p.guide-footnote{
		font-size: 16px;
		color: #fff;
		font-weight: bold;
	}
}


.max-home .service-content{
	position: relative;
	margin: 0 auto;
	width: 100%;
	height: 100%;
}
.max-home .service-inner{
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -271px;
	margin-left: -631px;
	width: 1263px;
	height: 542px;
	overflow: hidden;
}
.max-home .service-inner>.sider{
	float: left;
	width: 62%;
	height: 100%;
}
.max-home .service-info{
	float: right;
	padding: 51px 0;
	width: 30%;
	height: 100%;
}
.max-home .service-info-inner>div{
	margin-top: 58px;
	/*width: 220px;*/
	margin-bottom: 15px;
}
.max-home .service .service-info .service-logo-icon {
	width: 169px;
	height: 63px;
	margin-bottom: 15px;
}

.max-home .service-info-inner>button>img{
    width: 17px;
    height: 20px;
    margin-right: 5px;
}

.max-home .shopping-content-item>.title{
	position: relative;
	  padding: 29px 0px 0px 0px;
	  width: 100%;
	  height: 100px;
	  text-align: center;
	  color: #FFFFFF;
	z-index: 11;
}

.max-home .kit-info{
	position: relative;
	padding: 20px 17px;
	margin-right:20px;
	border: solid 2px #4A4A4A;
  margin-bottom: 5px;
}
.max-home .kit-info:hover{
	border: solid 2px #F8E71C;
  /* padding: 19px 16px; */
}
.max-home .kit-info:hover .price{
	/* bottom: 14px; */
}

.max-home .kit-price-info1{	
	width: 227px;
	height: 280px;	
}

.max-home .price{
	position: absolute;
	bottom: 15px;
  left: 0;
  width: 100%;
}
.max-home .price button{
  cursor: pointer
}
.max-home .stk-price-info1{	
	width: 227px;
	height: 280px;
}
.max-home .stk-info{
	position: relative;
	padding: 15px 18px 20px 17px;
	border: solid 2px #4A4A4A;
  margin-bottom: 5px;
}
.max-home .stk-info:hover{
	border: solid 2px #FF8800;
  /* padding: 14px 17px 20px 16px */
}
.max-home .stk-info:hover .price{
	/* bottom: 14px; */
}

.max-home .stk-info>img{
	width: auto;
	height: 50px;
}
.max-home .goods-details2 .stk-info>img{
	width: 90%;
	height: auto;
}

.max-home .kit-price-info2{
	width: 227px;
	height: 280px;
}
.max-home .kit-price-info2.trial-kit-box{
	width: 90%;
}
.max-home .kit-price-info2.trial-kit-box .very-large{
	font-size: 30px;
	font-weight: bold;
	display: block;
	margin-top: 35px;
}
.max-home .goods-details2>div {
	width: 194px;
	height: 273px;
	padding: 20px 10px;
}
.max-home .kit-price-info2>p>span {
	display: block;
}
.max-home .stk-price-info2{
	width: 227px;
	height: 280px;
}

.max-home .service-info-inner>button{
	cursor: pointer;
}

@media screen and (min-width: 2000px){
	.max-home .service-info {
		float: left;
		padding: 51px 30px;
		width: 28%;
	}
	.max-home .service-info-inner>div{
		margin-bottom: 30px;
	}
	.max-home .service .service-info .service-logo-icon {
		width: 254px;
		height: 95px;
	}
	.max-home .service-info-inner>p{
		margin-bottom: 43px;
		line-height: 33px;
		font-size: 27px;
		color: #9b9b9b;		
	}

	.max-home .service-info-inner>button{
		padding: 20px 60px;
		border: none;
		background-color: #FF8800;
		font-size: 21px;
		cursor: pointer;
		text-transform: uppercase;
	}

	.max-home .service-info-inner>button>span {
		vertical-align: middle;
		padding-left: 10px;
	}

	.max-home .service-info-inner>button>img{
		width: 26px;
		height: 30px;
		margin-right: 10px;
	}

	.max-home .header-countdown>p{
		height: 20px;
		font-size: 20px;
		color: #F8E71C;
	}

	.max-home .header-countdown>ul>li>span{
		font-size: 27px;
	}
	.max-home .header-countdown>ul>li>i{
		margin: 5px;
		font-size: 1px;
		font-style: normal;
	}

	.max-home .header-countdown>ul>li>span{
		font-size: 27px;
	}
	.max-home .header-countdown>ul>li>i{
		margin: 5px;
		font-size: 18px;
		font-style: normal;
	}

	.max-home .shopping-content-item>.title{
		  height: auto;
	  }

	  .max-home .shopping-content-item-header .title h5 {
		line-height: 29px;
		font-size: 24px;
		margin-top: 23px;
		text-align: center;
		color: #fff;
	}

	.max-home .shopping-content-item>.title>h5{
		line-height: 29px;
		font-size: 24px;
		margin-top: 23px;
	}

	.max-home .kit-info>p, .max-home .kit-price-info1>p{
    	margin-top: 22px;
		line-height: 29px;
		font-size: 23px;
		color: #F8E71C;
		text-align: center;
		padding-left: 7px;
		padding-right: 7px;
	}
.max-home .stk-price-info1>p, .max-home .stk-info>p{
  	margin-top: 22px;
  	color: #FF8800;
  	font-size: 22px;
	line-height: 29px;
	text-align: center;
}
	.max-home .stk-copyright>p{
		line-height: 24px;
		font-size: 21px;
		color: #fff;
	}

	.max-home .kit-info>h3{
		line-height: 35px;
		font-size: 29px;
		color: #F8E71C;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
	}

	.max-home .shopping-content-item-header .title h2 {
		line-height: 56px;
		font-size: 50px;
		font-weight: 400;
		text-align: center;
		color: #fff;
	}

	.max-home .shopping-content-item>.title>h2{
		line-height: 56px;
		font-size: 50px;
	}
	.max-home .goods-pic{
		width: 782px !important;
		height: 432px !important;
	}
	.max-home .goods-pic-left .free-gift-wrapper img {
		width: 225px !important;
		height: 225px !important;
	}
	.max-home .kit-price-info1{	
		width: 341px;
		height: 420px;
	}
	.max-home .kit-info{
		position: relative;
		padding: 30px 26px;
		margin-right:30px;
		border: solid 2px #4A4A4A;
	  margin-bottom: 10px;
	}

	.max-home .price{
		bottom: 23px;
	}
	.max-home .stk-price-info1{	
		width: 341px;
		height: 420px;
	}
	.max-home .stk-info{
		padding: 22px 27px 30px 26px;
	  margin-bottom: 7px;
	}
	.max-home .stk-info>img{
		width: 285px;
		height: 95px;
	}
	.max-home .kit-price-info2{
		width: 341px;
		height: 420px;
	}
	.max-home .stk-price-info2{
		width: 341px;
		height: 420px;
	}
	.free {
		font-size: 24px !important;
		line-height: 29px !important;
		margin: 50px 0 54px !important;
	}
}

@media screen and (max-width: 2000px){
	.max-home .service-info-inner>p{
		margin-bottom: 29px;
		/*width: 388px;*/
		line-height: 21px;
		font-size: 18px;
		color: #9b9b9b;
		
	}

	.max-home .service-info-inner>button{
		padding-left: 40px;
		padding-right: 40px;
		height: 46px;
		border: none;
		background-color: #FF8800;
		font-size: 12px;
		text-transform: uppercase;
	}

	.max-home .service-info-inner>button>span {
		vertical-align: middle;
		padding-left: 5px;
	}

	.max-home .header-countdown>p{
		height: 20px;
		font-size: 13px;
		color: #F8E71C;
	}

	.max-home .header-countdown>ul>li>span{
		font-size: 18px;
	}
	.max-home .header-countdown>ul>li>i{
		margin: 5px;
		font-size: 12px;
		font-style: normal;
	}

	.max-home .header-countdown>ul>li>span{
		font-size: 18px;
	}
	.max-home .header-countdown>ul>li>i{
		margin: 5px;
		font-size: 12px;
		font-style: normal;
	}

	.max-home .shopping-content-item-header .title h5 {
		line-height: 19px;
		font-size: 16px;
		margin-top: 15px;
		text-align: center;
		color: #fff;
	}

	.max-home .shopping-content-item>.title>h5{
		line-height: 19px;
		font-size: 16px;
    margin-top: 15px;
	}

	.max-home .kit-info>p, .max-home .kit-price-info1>p{
		margin-top: 15px;
		line-height: 19px;
		font-size: 15px;
		color: #F8E71C;
		text-align: center;
		padding-left: 5px;
		padding-right: 5px;
	}
.max-home .stk-price-info1>p, .max-home .stk-info>p{
  	margin-top: 15px;
  	color: #FF8800;
  	font-size: 15px;
	line-height: 19px;
	text-align: center;
}

	.max-home .stk-copyright>p{
		line-height: 16px;
		font-size: 14px;
		color: #fff;
	}

	.max-home .kit-info>h3{
		line-height: 23px;
		font-size: 19px;
		color: #F8E71C;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
	}

	.max-home .shopping-content-item-header .title h2 {
		line-height: 37px;
		font-size: 33px;
		font-weight: 400;
		text-align: center;
		color: #fff;
	}

	.max-home .shopping-content-item>.title>h2{
		line-height: 37px;
		font-size: 33px;
	}
}

.max-home .shopping{
	/*min-height: 1050px; */
  overflow: hidden
}
.max-home .header-countdown{
	text-align: center;
	height: 45px;
	line-height: 45px;
	background-color: #1A1A1A;
	visibility: hidden;
	display: none;
}
.max-home .header-countdown>p{
	display: inline-block;
	vertical-align: top;
	margin-top: 10px;
	margin-right: 13px;
}

.max-home .header-countdown>ul{
	display: inline-block;
	overflow: hidden;
	list-style: none;
}
.max-home .header-countdown>ul>li{
	float: left;
	color: #FFFFFF;
}

.max-home .shopping-content{
  overflow: hidden;
	position: relative;
}
.max-home .shopping-content-holder{
	/* position: absolute;
	width: 100%;
	top: 50%;
	margin-top: -300px; */
}
.max-home .shopping-content-holder2 {
	position: absolute;
	width: 100%;
	top: 50%;
	margin-top: -280px;
}
.max-home .shopping-content-item1{
  float: left;
	margin-top: 40px;
  margin-left: 3%;
	width: 47%;
	overflow: hidden;
	text-align:center;
}
.max-home .shopping-content-item2{
  float: left;
	margin-top: 40px;
	width: 47%;
	overflow: hidden;
	text-align: center;
  /* background-color: transparent */
}

.max-home .shopping-content-item2a {
	margin-top: 65px;
}

.gallery-show-arrow {
	display: none;
	position: absolute;
	top: 50%;
	margin-top: -15px;
	width: 15px;
	height: 30px;
	cursor: pointer;
}

#gallery-show-left {
	left: -25px;
}

#gallery-show-right {
	right: -25px;
}

@media screen and (max-height: 880px) {
	/* shopping section */
	.max-home .shopping-content-holder {
		top: 50%;
		margin-top: -350px;
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
	}
	.max-home .shopping-content-holder2 {
		max-width: none;
		margin-top: -235px;
	}
	.max-home .shopping-content-item1, .max-home .shopping-content-item2 {
		margin-top: 0px;
	}
	.max-home .shopping-content-holder2 .shopping-content-item1, .max-home .shopping-content-holder2 .shopping-content-item2 {
		margin-top: 40px;
	}
	.max-home .shopping-content-item>.title {
		padding-top: 5px;
	}
	.max-home .shopping-content-item>.title>h2 {
		line-height: 30px;
		font-size: 26px;
	}
	.max-home .shopping-content-item>.title>h5 {
		margin-top: 5px;
	}
	.max-home .shopping-content-item>.title {
		height: auto;
	}
	.max-home .goods-details {
		margin-top: -30px;
	}
	.max-home .goods-pic {
		height: 250px !important;
		width: 447px !important;
	}
	.max-home .shopping-content-holder2 .goods-pic {
		height: 324px !important;
		width: 580px !important;
	}
	.max-home .goods-pic-left .free-gift-wrapper img {
		width: 128px !important;
		height: 128px !important;
	}
	.max-home .kit-info {
		padding: 15px 17px !important;
		/* height: 250px !important; */
	}
	.max-home .goods-details2>div {
		padding: 20px 10px !important;
		height: 273px !important;
	}
	.max-home .kit-info>h3 {
		line-height: 23px;
		font-size: 19px;
	}
	.max-home .kit-info>p, .max-home .kit-price-info1>p {
		margin-top: 8px;
		font-size: 14px;
		line-height: 18px;
	}
	.max-home .goods-details .original {
		font-size: 14px !important;
		line-height: 17px !important;
	}
	.max-home .goods-details .discount {
		margin-top: 10px !important;
	}
	.max-home .stk-info {
		padding: 10px 18px 20px 17px !important;
		/* height: 250px !important; */
	}
	.max-home .stk-price-info1>p, .max-home .stk-info>p {
		font-size: 14px;
		line-height: 17px;
	}
	.free {
		margin: 13px 0 16px !important;
		font-size: 16px;
	}
	.max-home .free .strengthen {
		font-style: normal;
	}

	/* header */

	.max-home #header {
		line-height: 73px;
		height: 73px;
	}

	.max-home #nav .nav-logo-icon {
		width: 215px;
		height: 20px;
		margin-top: -5px;
	}

	.max-home .pagination-additional>li {
		line-height: 73px;
	}

	.max-home .header-right>ul>li {
		line-height: 73px;
		height: 73px;
	}

	.max-home .null {
		height: 73px;
	}

	.max-home .pagination-additional>li>a>span {
		bottom: 20px;
	}
	.pc-language-choose {
		top: 55px;
	}

	/* product videos */
	.max-home .model3-details {
		padding-top: 38px;
		height: 150px;
	}

	.max-home .model3-video1-1 {
		top: -76px;
	}
	.max-home .model5-video1-2 {
		bottom: 155px;
	}
	.max-home .model5-video2-2 {
		bottom: 130px;
	}

	.max-home .box-pic {
		top: 108px;
	}
	.max-home .box-details>.title {
		height: auto;
	}
	.max-home .box-details>.title>h1 {
		padding: 176px 0 0;
	}
	.max-home .box-details>h4 {
		font-size: 17px;
		line-height: 22px;
	}
	.max-home .model6-details>p, .box-details>p {
		margin-bottom: 10px;
	}
	.max-home .build>h4 {
		padding: 20px 0;
	}
	.max-home .build>div {
		margin-bottom: 20px;
	}

	/* gallery */
	.gallery-show-arrow {
		display: block;
	}
	.gallery {
		height: auto;
	}
}

@media screen and (max-height: 600px) {
	.max-home .box-details>.title>h1 {
		padding: 115px 0 0;
	}
}

.turnDeviceNotification {
	position:fixed;
	top: 0;
	left:0;
	height:100%;
	width:100%;
	display: none;
	z-index: 99999;
	background-image: url("../image/common/turn-device.png");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
  }

@media (orientation: landscape) {
	.turnDeviceNotification {
		display: block;
	}
}
/*
@media screen and (min-width: 500px) and (max-height: 500px) {
	.max-home .mobile .home-content-icon {
		margin-top: 0px;
	}
	.max-home .mobile .home-bottom, .max-home .mobile-ma .home-bottom {
		display: none;
	}
	.max-home .component.component-m {
		height: auto;
	}
	.max-home .component.component-m .page2 {
		height: 300px;
	}
	.max-home .mobile-model1-info>p {
		width: auto;
	}
}
*/
.max-home .bold {
	font-weight: bold;
}
@media screen and (min-width: 2000px){
	.max-home .goods-details{
		display: inline-block;
		overflow: hidden;
	}
}

@media screen and (max-width: 2000px){
	.max-home .goods-details{
		display: inline-block;
		overflow: hidden;
		margin-top: -15px;
	}
	.max-home .goods-details2{
		margin-top: 0px;
		display: flex;
	}
}
.max-home .goods-details>div.goods-pic {
	display: block;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}
.max-home .goods-pic-left .free-gift-wrapper {
	position: absolute;
	bottom: 16px;
	right: 23px;
	z-index: 2;
	cursor: pointer;
}
.max-home .goods-pic-left .free-gift-wrapper img {
	width: 150px;
	height: 150px;
}
.free-gift-image-wrapper {
	float: left;
	margin-right: 30px;
	border: 2px solid #cfa56c;
	padding: 5px;
	margin-left: 10px;
}
.free-gift-image-wrapper img {
	width: 280px;
}
.free-gift-text-wrapper {
	float: left;
	width: 280px;
	text-align: left;
	margin-top: 45px;
}
.free-gift-text-wrapper .free-gift-top-text {
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 30px;
}
.free-gift-text-wrapper .free-gift-disclaimer {
	font-size: 13px;
	line-height: 17px;
	color: #777;
}
@media screen and (max-width: 725px) {
	.free-gift-image-wrapper {
		float: none;
		margin-right: 0px;
		border: 2px solid #cfa56c;
		padding: 5px;
		margin-left: 0px;
	}
	.free-gift-image-wrapper img {
		width: 100%;
	}
	.free-gift-text-wrapper {
		width: 100%;
		float: none;
		margin-top: 10px;
		padding: 10px;
	}
	.free-gift-text-wrapper .free-gift-top-text {
		font-size: 13px;
		line-height: 18px;
		margin-bottom: 15px;
	}
	.free-gift-text-wrapper .free-gift-disclaimer {
		font-size: 10px;
		line-height: 14px;
	}
}
.max-home .goods-details>div{
	/* float: left; */
  display: inline-block;
  vertical-align: top;
}
.max-home .goods-pic{
	width: 521px;
	height: 288px;
}
.max-home .goods-pic-left{
	width: 580px;
	height: 324px;
}
.max-home .goods-pic>img:first-child{
	width: 100%;
	height: 100%;
}

@media screen and (max-height: 880px) and (min-width: 2000px) {
	.max-home .shopping-content-holder {
		margin-top: -350px !important;
	}
}

@media screen and (max-height: 700px) {
	.max-home .shopping-content-holder {
		margin-top: -300px !important;
	}
	.max-home .shopping-content-holder2 {
		margin-top: -235px !important;
	}
	.max-home .goods-pic {
		height: 200px !important;
		width: 358px !important;
	}
	.max-home .goods-pic-left .free-gift-wrapper img {
		width: 103px !important;
		height: 103px !important;
	}
}

@media screen and (max-height: 650px) {
	/* shopping page */
	.max-home .shopping-content-holder {
		margin-top: -281px !important;
	}
	.max-home .shopping-content-holder2 {
		margin-top: -215px !important;
	}
	.max-home .goods-pic {
		height: 180px !important;
		width: 322px !important;
	}
	.max-home .goods-pic-left .free-gift-wrapper img {
		width: 92px !important;
		height: 92px !important;
	}
	.max-home .shopping-content-item>.title>h2 {
		font-size: 20px;
		line-height: 22px;
	}
	.max-home .shopping-content-item>.title>h5 {
		font-size: 13px;
		line-height: 13px;
	}
	.max-home .kit-info>h3 {
		font-size: 15px;
		line-height: 18px;
	}
	.max-home .kit-info>p, .max-home .kit-price-info1>p {
		font-size: 12px;
		line-height: 15px;
	}
	.max-home .goods-details .original {
		font-size: 11px !important;
		line-height: 12px !important;
	}
	.max-home .goods-details .discount {
		font-size: 10px;
		height: 37px;
		line-height: 37px;
	}
	.max-home .stk-info>img {
		width: 144px;
		height: 48px;
	}
	.max-home .stk-price-info1>p, .max-home .stk-info>p {
		font-size: 12px;
		line-height: 15px;
	}
	/* .max-home .kit-info {
		height: 230px !important;
	}
	.max-home .stk-info {
		height: 230px !important;
	} */
	.free {
		font-size: 12px;
		line-height: 14px;
	}

	.concept-store-popup {
		position: absolute;
		width: 680px;
		height: 400px;
		background-color: #FFF;
		top: 50%;
		margin-top: -190px;
		left: 50%;
		margin-left: -340px;
		z-index: 20;
		text-align: center;
		padding: 6px;
	}
	.concept-store-inner {
		height: 100%;
		width: 100%;
		border: 2px solid #000;
		padding-left: 10px;
		padding-right: 10px;
	}
	.concept-store-popup .close-btn {
		position: absolute;
		right: 20px;
		top: 14px;
		font-size: 18px;
		cursor: pointer;
	}
	.concept-store-popup h3 {
		text-align: center;
		margin-top: 22px;
		margin-bottom: 16px;
		font-size: 20px;
	}
	.concept-store-popup .storepic1 {
		height: 160px;
		margin-bottom: 16px;
		margin-left: 2px;
		margin-right: 2px;
	}
	.concept-store-popup p {
		font-size: 13px;
		line-height: 16px;
	}
	.concept-store-popup .store-desc {
		margin-bottom: 10px;
	}
	.concept-store-popup .disney-logo {
		position: absolute;
		bottom: 25px;
		right: 25px;
	}
	.concept-store-popup .disney-logo img {
		width: 70px;
		height: 20px;
	}
}

@media screen and (max-height: 600px) {
	/* home page */
	.max-home .home-des {
		font-size: 29px;
		line-height: 34px;
		margin: 30px 0;
	}
	.max-home .content-inner {
		margin-top: -210px;
	}
	/* model 1 */
	.max-home .model1-info {
		margin-top: -180px;
	}
	.max-home .model1-info>h3 {
		font-size: 28px;
	}
	.max-home .model3-details {
		font-size: 30px;
	}
	/* model 2 dimensions */
	.max-home .model2-des {
		font-size: 14px;
		line-height: 15px;
	}
	.max-home .model2-des>h4 {
		font-size: 15px;
	}
	/* in the box */
	.max-home .box-details>.title>h1 {
		font-size: 28px;
	}
	/* guide */
	.max-home .guide-info-inner>h5 {
		font-size: 28px;
	}
}

.model6-disclaimer {
	position: absolute;
	right: 200px;
	bottom: 50px;
	color: #fff;
	padding: 0px 40px;
}

@media screen and (max-width: 1160px) {
	/* home page */
	.max-home .home-des {
		font-size: 28px;
		line-height: 36px;
	}
	/* model 1 */
	.max-home .model1-info>h3 {
		font-size: 29px;
		line-height: 38px;
	}
	.max-home .model1-info {
		padding-left: 40px;
		width: 36%;
	}
	.max-home .model1-info>p {
		font-size: 16px;
		line-height: 22px;
	}
	/* model 3-5 */
	.canvas-line>div {
		display: none;
	}
	.canvas-line {
		border-width: 0px 0px 0px !important;
	}
	.max-home .model3-video1-info>div>img {
		padding-bottom: 7px !important;
	}
	.max-home .model3-details {
		font-size: 30px;
	}
	/* model 6 */
	.max-home .model6-details>h2 {
		font-size: 20px;
		line-height: 28px;
	}
	.max-home .model6-details>h1 {
		font-size: 28px;
		line-height: 34px;
	}
	.model6-disclaimer {
		right: auto;
	}
	/* in the box */
	.max-home .box-details>.title>h1 {
		font-size: 28px;
	}
	/* guide */
	.max-home .guide-info-inner>h5 {
		font-size: 28px;
		line-height: 36px;
	}
	.max-home .guide-info-inner>p {
		margin-top: 0px;
		margin-bottom: 20px;
		font-size: 16px;
	}
	.max-home .guide-info-inner>p.guide-footnote {
		font-size: 14px;
	}
}

@media screen and (max-width: 1040px) {
	/* left side models */
	.max-home .model3-video1-info {
		top: auto !important;
		bottom: -128px;
	}
	.max-home .model3-video1-info>p {
		font-size: 16px !important;
		line-height: 21px !important;
	}
	/* right side models */
	.max-home .model3-video2-info {
		top: auto !important;
		bottom: -128px;
	}
	.max-home .model3-video2-info>p {
		font-size: 16px !important;
		line-height: 21px !important;
	}
}

@media screen and (max-width: 900px) {
	.max-home .box-pic {
		display: none;
	}
	.max-home .box-details {
		margin-left: auto;
		margin-right: auto;
		width: 530px;
	}

	.concept-store-popup {
		position: absolute;
		width: 680px;
		height: 400px;
		background-color: #FFF;
		top: 50%;
		margin-top: -190px;
		left: 50%;
		margin-left: -340px;
		z-index: 20;
		text-align: center;
		padding: 6px;
	}
	.concept-store-inner {
		height: 100%;
		width: 100%;
		border: 2px solid #000;
		padding-left: 10px;
		padding-right: 10px;
	}
	.concept-store-popup .close-btn {
		position: absolute;
		right: 20px;
		top: 14px;
		font-size: 18px;
		cursor: pointer;
	}
	.concept-store-popup h3 {
		text-align: center;
		margin-top: 22px;
		margin-bottom: 16px;
		font-size: 20px;
	}
	.concept-store-popup .storepic1 {
		height: 160px;
		margin-bottom: 16px;
		margin-left: 2px;
		margin-right: 2px;
	}
	.concept-store-popup p {
		font-size: 13px;
		line-height: 16px;
	}
	.concept-store-popup .store-desc {
		margin-bottom: 10px;
	}
	.concept-store-popup .disney-logo {
		position: absolute;
		bottom: 25px;
		right: 25px;
	}
	.concept-store-popup .disney-logo img {
		width: 70px;
		height: 20px;
	}
}

@media screen and (max-width: 530px) {
	.max-home .box-details {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media screen and (max-width: 725px) {
	.concept-store-popup {
		position: absolute;
		width: 300px;
		height: 510px;
		background-color: #FFF;
		top: 50%;
		margin-top: -245px;
		left: 50%;
		margin-left: -150px;
		z-index: 20;
		text-align: center;
		padding: 6px;
	}
	.concept-store-inner {
		height: 100%;
		width: 100%;
		border: 1px solid #000;
		padding-left: 10px;
		padding-right: 10px;
	}
	.concept-store-popup .close-btn {
		position: absolute;
		right: 14px;
		top: 8px;
		font-size: 20px;
		cursor: pointer;
	}
	.concept-store-popup h3 {
		text-align: center;
		margin-top: 18px;
		margin-bottom: 8px;
		font-size: 18px;
		line-height: 20px;
	}
	.concept-store-popup .storepic1 {
		height: 120px;
		margin-bottom: 3px;
		margin-left: 0px;
		margin-right: 0px;
	}
	.concept-store-popup p {
		font-size: 11px;
		line-height: 13px;
	}
	.concept-store-popup .store-desc {
		margin-bottom: 7px;
		margin-top: 8px;
	}
	.concept-store-popup .disney-logo {
		position: absolute;
		bottom: 20px;
		right: 50%;
		margin-right: -26px;
	}
	.concept-store-popup .disney-logo img {
		width: 53px;
		height: 15px;
	}
}

@media screen and (min-width: 2000px){
	.max-home .shopping-content-holder {
		max-width: 1800px;
		margin-left: auto;
		margin-right: auto;
		top: 50%;
		margin-top: -650px;
	}
	.max-home .shopping-content-holder2 {
		margin-top: -350px;
	}
	.max-home .goods-details .original{
		line-height: 29px;
		font-size: 21px;	
		text-decoration: line-through;
	}

	.max-home .goods-details .discount{
		margin-top: 19px;
		width: 285px;
		height: 67px;
		line-height: 67px;
		text-align: center;
		color: #000;
		border: none;
		font-weight: 400;
		font-size: 18px;
	}
	.max-home .goods-details2 .discount{
		width: 86%;
	}
}

@media screen and (max-width: 2000px){
	.max-home .goods-details .original{
		line-height: 19px;
		font-size: 14px;	
		text-decoration: line-through;
	}

	.max-home .goods-details .discount{
		margin-top: 13px;
		width: 190px;
		height: 45px;
		line-height: 45px;
		text-align: center;
		color: #000;
		border: none;
		font-weight: 400;
		font-size: 12px;
	}
	.max-home .goods-details2 .discount{
		width: 86%;
	}
}

.max-home .kit-info .original{
	color: #F8E71C;
}
.max-home .stk-info .original{
	color: #FF8800;
}

.max-home .kit-info .discount{
	background-color: #F8E71C;
	font-weight: bold;	
}
.max-home .stk-info .discount{
	background-color: #FF8800;
	font-weight: bold;
}

/*forter-info*/
.max-home .footer-bg {
	position: relative;
}
.max-home .footer-content {
	z-index: 2;
	position: absolute;
	top: 50%;
	margin-top: -250px;
	left: 50%;
	margin-left: -500px;
}
.max-home .footer-bg:before {
	content: ' ';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0.2;
	/*background-image: url('../image/gallery/Position_10.jpg');*/
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
}
.max-home .forter-info{
	margin: 104px auto 0;
	width: 1000px;
	height: 370px;
	position: relative;
	overflow: hidden;
}
.max-home .forter-info .footer-newsletter-sign-up {
	position: relative;
	border: 1px solid #287198;
	box-shadow: 0px 0px 20px #287198 inset;
	padding: 20px;
	float: left;
}
.max-home .forter-info .footer-newsletter-sign-up:before {
	content: ' ';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0.3;
	background-image: url('../image/gallery/Position_3.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}
.max-home .forter-info .footer-newsletter-heading {
	position: relative;
	z-index: 2;
	color: #fff;
	font-size: 24px;
	margin-bottom: 20px;
}
.max-home .forter-info .footer-newsletter-heading2 {
	position: relative;
	z-index: 2;
	color: #fff;
	font-size: 17px;
	margin-bottom: 20px;
}
.max-home .forter-info .footer-newsletter-sign-up-box {
	position: relative;
	z-index: 2;
}
.max-home .forter-info .footer-newsletter-sign-up-box input {
	background-color: transparent;
	border: 1px solid #FFF;
	padding: 10px 20px;
	color: #FFF;
	font-size: 17px;
	margin-bottom: 10px;
	margin-right: 10px;
	font-family: "Futura" !important;
}
.max-home .forter-info .footer-newsletter-sign-up-box input[type=submit] {
	text-transform: uppercase;
	font-weight: bold;
	background-color: #f7e53b;
	color: #000;
	border: 1px solid #f7e53b;
	-webkit-appearance: none;
}
.max-home .forter-info .footer-newsletter-sign-up-box input::placeholder {
	color: #CCC;
}
.max-home .forter-info .footer-newsletter-fineprint {
	position: relative;
	z-index: 2;
	color: #CCC;
	font-size: 12px;
}
.max-home .stk-copyright {
	z-index: 2;
}
.max-home .forter-info .footer-questions {
	float: right;
}
.max-home .forter-info .footer-questions-heading {
	color: #fff;
	font-size: 24px;
	margin-bottom: 20px;
}
.max-home .forter-info .footer-questions-content {
	color: #fff;
	font-size: 14px;
	width: 340px;
	line-height: 16px;
	margin-bottom: 10px;
}
.max-home .forter-info .footer-questions-block {
	margin-bottom: 15px;
}
.max-home .forter-info .footer-social img {
	height: 30px;
	margin-right: 20px;
}

@media screen and (min-width: 1800px) {
	.max-home .footer-content {
		margin-top: -320px;
		margin-left: -750px;
	}
	.max-home .forter-info{
		margin: 124px auto 0;
		width: 1500px;
		height: 515px;
	}
	.max-home .forter-info .footer-newsletter-sign-up {
		padding: 30px;
	}
	.max-home .forter-info .footer-newsletter-heading {
		font-size: 36px;
		margin-bottom: 30px;
		line-height: 40px;
	}
	.max-home .forter-info .footer-newsletter-heading2 {
		font-size: 26px;
		margin-bottom: 30px;
	}
	.max-home .forter-info .footer-newsletter-sign-up-box input {
		padding: 15px 30px;
		font-size: 26px;
		margin-bottom: 15px;
		margin-right: 15px;
	}
	.max-home .forter-info .footer-newsletter-fineprint {
		font-size: 18px;
	}
	.max-home .forter-info .footer-questions-heading {
		font-size: 36px;
		margin-bottom: 30px;
	}
	.max-home .forter-info .footer-questions-content {
		font-size: 21px;
		width: 510px;
		line-height: 24px;
		margin-bottom: 15px;
	}
	.max-home .forter-info .footer-questions-block {
		margin-bottom: 15px;
	}
}

.max-home .stk-icom{
	position: absolute;
	top: 0;
	left: 0;
	width: 64px;
	height: 79px;	
}
.max-home .stk-icom>img{
	width: 100%;
	height: 100%;
}
.max-home .stk-introduce{
	position: absolute;
	top:0;
	left: 154px;
	width: 645px;
	height: 100%;
}

.max-home .about-content .title {
	font-size: 36px;
}

.max-home .about-content .content>div>p {
	font-size: 18px;
	line-height: 22px;
	color: #DDD;
}

@media screen and (min-width: 2000px) {
	.max-home .about-content .title {
		font-size: 36px;
		line-height: 42px;
	}
	
	.max-home .about-content .content>div>p {
		font-size: 27px;
		line-height: 33px;
	}
}

@media screen and (min-width: 2000px){
	.max-home .stk-introduce>p{
		line-height: 23px;
		font-size: 20px;
		color: #fff;
	}
}

@media screen and (max-width: 2000px){
	.max-home .stk-introduce>p{
		line-height: 21px;
		font-size: 18px;
		color: #fff;
	}
}

.max-home .stk-introduce>p>a{
	margin-left: 5px;
	color: #fff;
	text-decoration: underline;
}
.max-home .stk-introduce>p>span{
	color: #F8E71C;
}
.max-home .stk-copyright{
	position: absolute;
	left: 0;
	bottom: 30px;
}
.max-home .stk-copyright.emf {
	bottom: 10px;
}

.max-home .stk-copyright>p>a{
	color: #fff;
	text-decoration: underline;
}
.max-home .stk-copyright>p>span{
	margin: 0 6px;
}
.max-home .Brand-links{
	position: absolute;
	top: 0;
	left: 901px;
}
.max-home .Brand-links>.head{
	padding-left: 5px;
}
.max-home .Brand-links>.head>img{
	margin-right: 26px;
}
.max-home .Brand-links>.Brand{
	margin-top: 31px;
	width: 253px;
}
.max-home .Brand-links>.Brand>img{
	margin:0 11px 11px 0;
	width: 55px;
	height:30px;	
}
.max-home .Brand-links>.Brand>img:nth-child(4n){
	margin-right: 0;
}
/*h1{	
	padding: 100px;
	height: 2000px;
	color: #fff;
}
h2{
	color: #fff;
}*/

#scroller Section:first-child{
	height: 100%;
}
#scroller Section{
	height: 0px;
	overflow: auto;
}

.test{
	/*overflow: auto;*/
}
.max-home .stk{
	transition: all 1s ease-in-out;
}
.pop{
  position: fixed;
  width:650px;
  /*height:370px;*/
  background:rgba(255,255,255,1);
  top: 50%;
  left: 50%;
  z-index: 9999;
  padding: 50px 50px 0 50px;
  margin-top: -150px;
  margin-left: -325px;
}
@media screen and (max-width: 900px) {
	.pop {
		width: 90%;
		left: 50%;
		margin-left: -45%;
	}
}
.pop h3{
  font-size: 27px;
  margin-top: 66px;
  color: #000;
  margin-bottom: 19px;
}
.pop p{
  font-size: 14px;
  line-height: 17px;
  color: #000;
  margin-bottom: 19px;
}
.pop p.footnote {
	font-size: 11px;
	line-height: 12px;
	color: #777;
}
.pop .btn{
  width:236px;
  height:45px;
  border:2px solid rgba(26,26,26,1);
  text-align: center;
  line-height: 45px;
  margin-bottom: 50px;
  cursor: pointer;
}
.pop .close{
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.mobilepop{
  width: 21.071429rem;
  position: fixed;
  background:rgba(255,255,255,1);
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9999;
  padding: 50px 0 0 0;
}
.mobilepop h3{
  margin: 0 auto;
  font-size: 20px;
  line-height: 24px;
  margin-top: 3.714286rem;
  color: #000;
  margin-bottom: 1.357143rem;
  width: 16.857143rem;
  text-align: center;
}
.mobilepop p{
  margin: 0 auto;
  font-size: 13px;
  line-height: 17px;
  color: #000;
  margin-bottom: 1.357143rem;
  width: 16rem;
  text-align: center;
}
.mobilepop p.footnote {
	font-size: 11px;
	color: #777;
}
.mobilepop .btn{
  margin: 0 auto;
  width:16.857143rem;
  height:3.214286rem;
  border:2px solid rgba(26,26,26,1);
  text-align: center;
  line-height: 3.214286rem;
  margin-bottom: 1.428571rem;
}
.mobilepop .close{
  width: 1.428571rem;
  height: 1.428571rem;
  position: absolute;
  right: 1.428571rem;
  top: 1.428571rem  ;
  cursor: pointer;
}

 /* 媒体查询 */
 @media screen and (min-width: 2000px){
   .max-home .pagination-additional>li>a{
     font-size: 20px
   }
   .max-home .header-right>ul>li>span{
     font-size: 18px
   }
   .max-home .header-right>ul>li>h3{
     font-size: 16px;
   }
 }

 @media screen and (max-width: 1100px){
   .max-home .model2-pic{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
   }
    .max-home .model2-info{
      top:-20px;
    }
 }
 @media screen and (max-width: 800px){
   .max-home .model2-info{
      top:-50px;
		}
		
		.max-home .guide-content{
			top: 100px
		}
		.max-home .guide-inner{
			height: auto;
			top: 0;
			margin-top: 0;
			transform: translateY(0);
		}
		.max-home .guide-inner>.sider{
			float: initial;
			margin: 0 auto;
			width: 80%;
			height: 430px;
		}
		.max-home .guide-info{
			float: initial;
			margin: 0 auto;
			text-align: center;
			width: 100%;
		}
		.max-home .service{
			height: 940px;
		}
		.max-home .service-inner{
			position: relative;
			width: 100%;
			height: auto;
			top: 0;
			left:0;
			margin-left: 0;
		 margin-top: 0;
		 padding-top: 120px;
		}
		.max-home .service-inner video{
			width: 100%
		}
		.max-home .service-inner>.sider{
			margin: 0 auto;
			float:initial
		}
		.max-home .service-info{
		 margin: 0 auto;
		 float:initial;
		 text-align: center;
		 width: 100%;
		 padding-left: 35px;
		 padding-right: 35px;
		}
		.max-home .service-info-inner>div{
			margin: 0 auto;
		}
 }
 @media screen and (max-width: 1250px){
  .max-home .Brand-links {
      position: relative;
      top: 0;
      left: 0;
      float: right;
  }
 }
  @media screen and (max-width: 900px){
    .max-home .guide-inner>.sider{
     height: 330px;
   }
 }
 @media screen and (max-width: 1210px){
    .max-home .shopping {
      /* min-height: 2000px; */
      overflow: hidden;
    }
 }
 @media screen and (max-width: 1002px){
 
    .max-home .goods-details{
      width: 97%
    } 
 }
 .shopping-slider {
	 display: none;
	 margin-left: auto;
	 margin-right: auto;
	 width: 600px;
	 position: relative;
 }
 .shopping-slide {
	display: none;
	overflow: hidden;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	width: 520px;
 }
 .shopping-content-holder2 .shopping-slide {
	width: auto;
}
 .shopping-show-arrow {
	position: absolute;
	top: 50%;
	margin-top: -15px;
	width: 15px;
	height: 30px;
	cursor: pointer;
}
#shopping-show-left {
	left: 25px;
}

#shopping-show-right {
	right: 25px;
}
.shopping-item-note {
	color: #fff;
	font-size: 18px;
	margin-top: 30px;
}
.shopping-item-note-anchor {
	cursor: pointer;
	text-decoration: underline;
	font-weight: bold;
}
 @media screen and (max-width: 1100px){
   .max-home .goods-details>div{
     float: initial;  
   }
   .max-home .goods-details>div:first-child{
      display: block;
      margin: 0 auto;
   }
   .max-home .shopping-content-item1, .max-home .shopping-content-item2 {
	   display: none;
   }
   .max-home .shopping-content-holder2 {
		margin-top: -350px;
	}
   .shopping-slider {
	   display: block;
   }
   .shopping-slide1 {
		display: block;
	}
	.free {
		margin: 23px 0 36px !important;
	}
	.max-home .shopping-content-item>.title {
		padding: 0px 0px 0px 0px;
	}
 }
 @media screen and (max-width: 1024px){
	.max-home .forter-info {
		width: auto;
	}
	.max-home .forter-info .footer-newsletter-sign-up {
		margin-right: 55px;
	}
	.max-home .forter-info .footer-newsletter-heading {
		font-size: 20px;
	}
	.max-home .forter-info .footer-questions-block {
		margin-bottom: 15px;
	}
	.max-home .footer-content {
		margin-left: -400px;
	}
 }
 @media screen and (max-width: 820px){
	.max-home .footer-content {
		margin-left: auto;
		margin-right: auto;
		top: auto;
		left: auto;
		position: relative;
		margin-top: 10px;
		overflow: visible;
	}
	.max-home .forter-info {
		margin: 0 auto;
		height: auto;
		overflow: visible;
	}
	.max-home .forter-info .footer-newsletter-sign-up {
		float: none;
		margin-right: 0;
		text-align: center;
		margin-bottom: 30px;
	}
	.max-home .forter-info .footer-questions {
		float: none;
		text-align: center;
	}
	.max-home .forter-info .footer-questions-content {
		width: auto;
	}
	.max-home .forter-info .footer-social img {
		margin-left: 10px;
		margin-right: 10px;
	}
	.max-home .stk-copyright {
		left: 50%;
		margin-left: -230px;
		bottom: -50px;
		text-align: center;
	}
	.max-home .stk-copyright.emf {
		bottom: -110px;
	}
 }
 @media screen and (max-width: 1100px) and (max-height: 880px) {
	.max-home .shopping-content-holder {
		margin-top: -380px;
	}
	.shopping-item-note {
		font-size: 16px;
		margin-top: 20px;
	}
	.free {
		font-size: 13px;
		line-height: 16px;
		margin: 20px 0 36px !important;
	}
 }
  @media screen and (min-width: 1600px){
      .max-home .goods-details>.goods-pic{
        display: block;
      }
 }
  @media screen and (max-width: 930px){
   .max-home .goods-details>div{
      display: inline-block;
      float:initial;
      vertical-align: top;
   }
   .max-home .shopping-content-item1,.max-home .shopping-content-item2{
	float:initial;
	width: 100%;
	margin-left: 0;
  }
 }

/* PC */
.canvas-virtual {
	top: 182px;
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	/* border: red 1px solid; */
}
@media screen and (max-height: 880px) {
	.canvas-virtual {
		top: 132px;
	}
}
.canvas-virtual .line{
	transition: height 1s, width 0.5s;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 10px #fff, 0 0 14px #fff;
	position: absolute;
}
.canvas-virtual .t-l{
	top: 0px;
	right: 50%;
	width: 0%;
  height: 2px;
}
.canvas-virtual .t-r{
	top: 0px;
	left: 50%;
	width: 0%;
  height: 2px;
}
.canvas-virtual .v-l{
	top: 0px;
	left: 14%;
	width: 2px;
  height: 0%;
}
.canvas-virtual .v-r{
	top: 0px;
	right: 14%;
	width: 2px;
  height: 0%;
}

.canvas-virtual .b-l{
	bottom: 33%;
	left: 14%;
	width: 0%;
  height: 2px;
}
.canvas-virtual .b-r{
	bottom: 33%;
	right: 14%;
	width: 0%;
  height: 2px;
}
.canvas-virtual.engaged .v-l, 
.canvas-virtual.engaged .v-r{
	height: 67%;
}

.canvas-virtual.engaged .t-l, 
.canvas-virtual.engaged .t-r{
	width: 36%;
}
.canvas-virtual.engaged .b-l, 
.canvas-virtual.engaged .b-r{
	width: 36%;
}

.canvas-virtual .v-l,
.canvas-virtual .v-r{
	transition-delay: 0.5s;
}

.canvas-virtual .b-l,
.canvas-virtual .b-r{
	transition-delay: 1.5s;
}



#pc-model2-canvas-virtual-l,#pc-model2-canvas-virtual-l1,#pc-model2-canvas-virtual-l2{
    transform: rotate(71deg);
}
#pc-model2-canvas-virtual-l3,#pc-model2-canvas-virtual-l4{
  transform: rotate(66deg);
}
#pc-model2-canvas-virtual-w,#pc-model2-canvas-virtual-w1{
    transform: rotate(-75deg);
}
#pc-model2-canvas-virtual-w2{
    transform: rotate(-74deg);
}
#pc-model2-canvas-virtual-w3{
    transform: rotate(-77.5deg);
}
#pc-model2-canvas-virtual-w4{
    transform: rotate(-78.5deg);
}

.canvas-virtual-model2 {
	top: 214px;
	display: block;
	width: 2px;
	height: 100%;
	position: absolute;
	/* border: red 1px solid; */
}
.canvas-virtual-model2 .line{
	transition: height 1s, width 0.5s;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 10px #fff, 0 0 14px #fff;
	position: absolute;
}
.canvas-virtual-model2 .line1{
	transition: height 1s, width 0.5s;
  background: #666;
  border-radius: 1px;
    /* box-shadow: 0 0 10px #fff, 0 0 14px #fff; */
	position: absolute;
  top: 0px;
	left: 0px;
	width: 2px;
  height: 0%;
}
.canvas-virtual-model2 .t-l{
	top: 0px;
	right: 50%;
	width: 0%;
  height: 2px;
}
.canvas-virtual-model2 .t-r{
	top: 0px;
	left: 0px;
	width: 0%;
  height: 2px;
}
.canvas-virtual-model2 .v-l{
	top: 0px;
	left: 0px;
	width: 2px;
  height: 0%;
}
.canvas-virtual-model2 .v-r{
	top: 0px;
	right: 14%;
	width: 2px;
  height: 0%;
}

.canvas-virtual-model2 .b-l{
	bottom: 26%;
	left: 14%;
	width: 0%;
  height: 2px;
}
.canvas-virtual-model2 .b-r{
	bottom: 26%;
	right: 14%;
	width: 0%;
  height: 2px;
}
.canvas-virtual-model2.engaged .v-l, 
.canvas-virtual-model2.engaged .v-r,
.canvas-virtual-model2.engaged .line1{
	height: 100%;
}

.canvas-virtual-model2.engaged .t-l, 
.canvas-virtual-model2.engaged .t-r{
	width: 100%;
}
.canvas-virtual-model2.engaged .b-l, 
.canvas-virtual-model2.engaged .b-r{
	width: 100%;
}

.canvas-virtual-model2 .v-l,
.canvas-virtual-model2 .v-r{
	transition-delay: 0.5s;
}

.canvas-virtual-model2 .b-l,
.canvas-virtual-model2 .b-r{
	transition-delay: 1.5s;
}



/* mobile */
.mobile-canvas-virtual {
	top: 0;
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
  z-index: 11;
	/* border: red 1px solid; */
}
.mobile-canvas-virtual .line{
	transition: height 1s, width 0.5s;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 10px #fff, 0 0 14px #fff;
	position: absolute;
}
.mobile-canvas-virtual .t-l{
	top: 98px;
	right: 79%;
	width: 0%;
  height: 2px;
}
.mobile-canvas-virtual .t-r{
	top: 15%;
	left: 79%;
	width: 0%;
  height: 2px;
}
.mobile-canvas-virtual .v-l{
	top: 15%;
	left: 6%;
	width: 2px;
  	height: 0;
}
.mobile-canvas-virtual .v-r{
	top: 15%;
	right: 6%;
	width: 2px;
  	height: 0;
}

.mobile-canvas-virtual .b-l{
	bottom: 8%;
	right: 6%;
	width: 0%;
  height: 2px;
}
.mobile-canvas-virtual .b-r{
	bottom: 8%;
	left: 6%;
	width: 0%;
  height: 2px;
}
.mobile-canvas-virtual.engaged .v-l, 
.mobile-canvas-virtual.engaged .v-r{
	/*height: 77.5%;*/
	height: 504px;
}

.mobile-canvas-virtual.engaged .t-l, 
.mobile-canvas-virtual.engaged .t-r{
	width: 15%;
}
.mobile-canvas-virtual.engaged .b-l, 
.mobile-canvas-virtual.engaged .b-r{
	width: 44%;
}

.mobile-canvas-virtual .v-l,
.mobile-canvas-virtual .v-r{
	transition-delay: 0.5s;
}

.mobile-canvas-virtual .b-l,
.mobile-canvas-virtual .b-r{
	transition-delay: 1.5s;
}

/* mobile-modelTwo-line */
.mobile-model2-line{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
}
#mobile-model2-canvas-virtual-l,#mobile-model2-canvas-virtual-l1,#mobile-model2-canvas-virtual-l2{
    transform: rotate(71deg);
}
#mobile-model2-canvas-virtual-l3,#mobile-model2-canvas-virtual-l4{
  transform: rotate(66deg);
}
#mobile-model2-canvas-virtual-w,#mobile-model2-canvas-virtual-w1{
    transform: rotate(-75deg);
}
#mobile-model2-canvas-virtual-w2{
    transform: rotate(-74deg);
}
#mobile-model2-canvas-virtual-w3{
    transform: rotate(-77.5deg);
}
#mobile-model2-canvas-virtual-w4{
    transform: rotate(-78.5deg);
}

.mobile-canvas-virtual-model2 {
	top: 214px;
	display: block;
	width: 0.5px;
	height: 100%;
	position: absolute;
	/* border: red 1px solid; */
}
.mobile-canvas-virtual-model2 .line{
	transition: height 0.5s, width 0.5s;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 10px #fff, 0 0 14px #fff;
	position: absolute;
}
.mobile-canvas-virtual-model2 .line1{
	transition: height 1.5s, width 0.5s;
  background: #666;
  border-radius: 1px;
    /* box-shadow: 0 0 10px #fff, 0 0 14px #fff; */
	position: absolute;
  top: 0px;
	left: 0px;
	width: 0.5px;
  height: 0%;
}
.mobile-canvas-virtual-model2 .line2{
	transition: height 0.5s, width 0.5s;
  background: #666;
  border-radius: 1px;
    /* box-shadow: 0 0 10px #fff, 0 0 14px #fff; */
	position: absolute;
  top: 0px;
	left: 0px;
	width: 0.5px;
  height: 0%;
}
.canvas-virtual-model2 .t-l{
	top: 0px;
	right: 50%;
	width: 0%;
  height: 2px;
}
.mobile-canvas-virtual-model2 .t-r{
	top: 0px;
	left: 0px;

	width: 0%;
    height: 2px;
}
.mobile-canvas-virtual-model2 .v-l{
	top: 0px;
	left: 0px;
	width: 1.5px;
  height: 0%;
}
.mobile-canvas-virtual-model2 .v-r{
	top: 0px;
	right: 14%;
	width: 1.5px;
  height: 0%;
}

.mobile-canvas-virtual-model2 .b-l{
	bottom: 26%;
	left: 14%;
	width: 0%;
  height: 2px;
}
.mobile-canvas-virtual-model2 .b-r{
	bottom: 26%;
	right: 14%;
	width: 0%;
  height: 2px;
}
.mobile-canvas-virtual-model2.engaged .v-l, 
.mobile-canvas-virtual-model2.engaged .v-r,
.mobile-canvas-virtual-model2.engaged .line1, 
.mobile-canvas-virtual-model2.engaged .line2{
	height: 100%;
}

.mobile-canvas-virtual-model2.engaged .t-l, 
.canvas-virtual-model2.engaged .t-r{
	width: 100%;
}
.mobile-canvas-virtual-model2.engaged .b-l, 
.mobile-canvas-virtual-model2.engaged .b-r{
	width: 100%;
}

.mobile-canvas-virtual-model2 .v-l,
.mobile-canvas-virtual-model2 .v-r{
	/* transition-delay: 0.5s; */
}

.mobile-canvas-virtual-model2 .b-l,
.mobile-canvas-virtual-model2 .b-r{
	transition-delay: 1.5s;
}

.choose-term-duration {
	position: absolute;
	bottom: -92px;
	right: 0;
	width: 216px;
	border: 1px solid #000;
}

.choose-term-duration div {
	position: relative;
	z-index: 11;
	padding-left: 18px;
	height: 44px;
	line-height: 44px;
	font-size: 14px;
	color: #000;
	background-color: #fff;
}

.choose-term-duration div:hover {
	background-color: #D8D8D8;
}

.mobilecart .choose-term-duration {
	left: 0px;
}

.term-duration {
	position: relative;
	display: inline-block;
	margin-top: 0px;
	width: 40px;
	margin-right: 20px;
	margin-bottom: 15px;
}

.term-duration input {
	flex: 1;
	width: 40px;
	border-bottom: 1px solid #000;
	padding-bottom: 10px;
	padding-left: 0;
	font-size: 16px;
}

.checkbox-paybyterm {
	margin-bottom: 20px;
}

.inst-amt {
	margin-bottom: 20px;
	font-size: 16px;
}

.order-pagination {
    padding-left: 1%;
}

.order-pagination.morder-paging {
	padding: 0 1.428571rem;
}

.order-pagination {
    line-height: 3;
}

.order-pagination li {
   display: inline-block;
}

.order-pagination a {
    border: 1px solid #ddd;
    border-right: 0;
    padding: 6px 12px;
    font-size: 0.8rem;
    cursor: pointer;
    color: black;
    margin-bottom: 40px;
}

.order-pagination .first a {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.order-pagination .last a {
    border-right: 1px solid #ddd;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.order-pagination .last a {
    border-right: 1px solid #ddd;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.order-pagination .active a {
    cursor: default;
    pointer-events: none;
    background-color: black;
    border-color: black;
    color: rgb(255, 255, 255);
}

.order-pagination .disabled a {
    cursor: default;
    pointer-events: none;
    color: rgb(165, 165, 165);
}

.mTable {
	overflow: auto;
	flex:1
}

.mTable > div {
	padding: 0 1.428571rem;
}

.orderWrapper {
	display:flex;
	flex-direction:column;
	height: calc(98vh - 3.571429rem - 3.214286rem);
	height: calc(var(--vh, 1vh) * 100 - 3.571429rem - 3.214286rem);
	overflow:hidden;
}


.other-product {
	min-width:90px;
	text-align: center;
}

.other-product 	.choose-product {
	top: 55px;
}

.choose-product{
	position: absolute;
	top: 70px;
	left: 0;
	width: 100%;
  }

  .choose-product div {
	line-height: 30px;
	margin-top: 10px;
  }
  .choose-product a {
	color: #FFFFFF;
	font-size: 14px;
	text-align:center;
  }

  .choose-product a:hover {
	text-shadow: 0 0 20px #ffffff, 0 0 10px #ffffff, 0 0 50px #ffffff;
  }