.accessories-section .section-title {
    line-height: 37px;
    font-size: 33px;
    font-weight: 400;
    text-align: center;
    color: #fff;
}

.accessories-section .section-desc {
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
}

.accessories-section .accessories-section-holder {
    top: 50%;
    margin-top: -335px;
    width: 100%;
    position: absolute;
}

.accessories-section .accessories-section-center {
    text-align: center;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.accessories-section .accessories-section-center h4 {
    color: #fff;
}

.accessories-section .section-row {
    text-align: center;
    margin-bottom: 20px;
}

.accessories-section .section-row-item {
    width: 350px;
    height: 215px;
    border: 1px solid #888;
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
    cursor: pointer;
    position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.section-row-item-bg01 {
    background-image: url("../image/home/acc-classic-plaque.png");
}

.section-row-item-bg02 {
    background-image: url("../image/home/acc-technical-plaque.png");
}

.section-row-item-bg03 {
    background-image: url("../image/home/acc-bundle-plaque.png");
}

.section-row-item-bg01b {
    background-image: url("../image/home/acc-classic-plaque-mobile.png");
}

.section-row-item-bg02b {
    background-image: url("../image/home/acc-technical-plaque-mobile.png");
}

.section-row-item-bg03b {
    background-image: url("../image/home/acc-bundle-plaque-mobile.png");
}

.section-row-item-bg04 {
    background-image: url("../image/home/acc-metal-tablestand.png");
}

.section-row-item-bg04b {
    background-image: url("../image/home/acc-metal-tablestand-mobile.png");
}

.section-row-item-bg05 {
    background-image: url("../image/home/acc-acrylic-floorstand.png");
}

.section-row-item-bg06 {
    background-image: url("../image/home/acc-wall-mount.png");
}

.accessories-section .section-row-item.section-row-item-bg01 {
    background-size: 90%;
    background-position: center 80%;
}
.accessories-section .section-row-item.section-row-item-bg02 {
    background-size: 90%;
    background-position: center 80%;
}
.accessories-section .section-row-item.section-row-item-bg03 {
    background-size: 90%;
    background-position: center 100%;
}

.section-row-item-see-all {
    width: 710px;
    height: 50px;
    background-color: #f8e71c;
    border: 1px solid #f8e71c;
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
    cursor: pointer;
    position: relative;
}

.section-row-item-see-all .section-item-label-see-all {
    position: absolute;
    top: 50%;
    margin-top: -11px;
    width: 100%;
    height: 10px;
    color: #000;
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    z-index: 2;
}

.accessories-section .section-row-item .section-item-stripe {
    position: absolute;
    bottom: 9%;
    right: 5%;
    width: 25%;
    height: 2px;
    border-top: 1px solid #F8E71C;
    z-index: 2;
}

.accessories-section .section-row-item .section-item-stripe-top {
    position: absolute;
    top: 20%;
    left: 5%;
    width: 25%;
    height: 2px;
    border-top: 1px solid #F8E71C;
    z-index: 2;
}

.accessories-section .section-row-item .section-item-label {
    position: absolute;
    bottom: 22%;
    right: 5%;
    width: 80%;
    height: 2px;
    color: #fff;
    font-size: 19px;
    text-align: right;
    font-weight: bold;
    z-index: 2;
}

.accessories-section .section-row-item .section-item-label-top {
    position: absolute;
    top: 8%;
    left: 5%;
    width: 80%;
    height: 2px;
    color: #fff;
    font-size: 19px;
    text-align: left;
    font-weight: bold;
    z-index: 2;
}

.accessories-section .section-row-item .section-item-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-color: #000;
}

@media screen and (max-width: 1220px) {
    .accessories-section .accessories-section-center {
        width: 1000px;
    }
    .accessories-section .section-row-item {
        width: 280px;
        height: 172px;
    }
    .accessories-section .accessories-section-holder {
        margin-top: -235px;
    }
    .accessories-section .section-desc {
        margin-bottom: 20px;
    }
    .section-row-item-see-all {
        width: 880px;
    }
    .accessories-section .section-row-item .section-item-label {
        bottom: 23%;
        font-size: 16px;
    }
    .accessories-section .section-row-item .section-item-label-top {
        font-size: 16px;
    }
}

@media screen and (max-height: 800px) {
    .accessories-section .accessories-section-center {
        width: 1000px;
    }
    .accessories-section .section-row-item {
        width: 280px;
        height: 172px;
    }
    .accessories-section .accessories-section-holder {
        margin-top: -235px;
    }
    .accessories-section .section-desc {
        margin-bottom: 20px;
    }
    .section-row-item-see-all {
        width: 880px;
    }
    .accessories-section .section-row-item .section-item-label {
        bottom: 23%;
        font-size: 16px;
    }
    .accessories-section .section-row-item .section-item-label-top {
        font-size: 16px;
    }
}

@media screen and (max-width: 1024px) {
    .accessories-section .accessories-section-center {
        width: 100%;
    }
    .accessories-section .section-row {
        margin-bottom: 0px;
    }
    .accessories-section .section-row-item {
        width: 200px;
        height: 123px;
        margin-bottom: 20px;
    }
    .accessories-section .accessories-section-holder {
        margin-top: -215px;
    }
    .accessories-section .section-desc {
        margin-bottom: 20px;
    }
    .section-row-item-see-all {
        width: 640px;
    }
    .accessories-section .section-row-item .section-item-label {
        bottom: 23%;
        font-size: 16px;
    }
    .accessories-section .section-row-item .section-item-label-top {
        font-size: 16px;
    }
    .accessories-section h4 {
        text-align: center;
        color: #fff;
    }
}

@media screen and (max-width: 700px) {
    .accessories-section .section-title {
        line-height: 26px;
        font-size: 23px;
        margin-bottom: 20px;
    }
    .accessories-section .section-row-item .section-item-mask {
        opacity: 0.3;
        background-color: #000;
    }
    .accessories-section .section-row-item {
        width: 320px;
        height: 125px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 15px;
    }
    .accessories-section .accessories-section-holder {
        margin-top: -315px;
    }
    .section-row-item-see-all {
        width: 300px;
        height: 40px;
        margin-bottom: 20px;
    }
    .section-row-item-see-all .section-item-label-see-all {
        font-size: 15px;
        margin-top: -8px;
    }
    .accessories-section .section-row-item.section-row-item-bg06 {
        background-position: center top;
    }
    .accessories-section .section-row-item .section-item-label {
        font-weight: bold;
        bottom: 27%;
    }
    .accessories-section .section-row-item .section-item-stripe-top {
        top: 27%;
    }
}

@media screen and (min-width: 2000px) {
    .accessories-section .accessories-section-center {
        width: 1500px;
    }
    .accessories-section .section-row-item {
        width: 440px;
        height: 270px;
    }
    .accessories-section .accessories-section-holder {
        margin-top: -375px;
    }
    .section-row-item-see-all {
        width: 900px;
    }
    .accessories-section .section-title {
        line-height: 56px;
        font-size: 50px;
    }
    .accessories-section .section-desc {
        line-height: 29px;
        font-size: 24px;
        margin-top: 23px;
    }
}

@media screen and (min-width: 700px) {
    .accessories-section .section-row-item:hover > .section-item-mask {
        display: none;
    }
    
    .accessories-section .section-row-item:hover > .section-item-stripe {
        display: none;
    }
    
    .accessories-section .section-row-item:hover > .section-item-label {
        display: none;
    }
    
    .accessories-section .section-row-item:hover > .section-item-stripe-top {
        display: none;
    }
    
    .accessories-section .section-row-item:hover > .section-item-label-top {
        display: none;
    }
}